<template lang="pug">
g.zone(:transform="transform")
	plan-area(v-for="a in zone.areas", :area="a", :key="a.uuid", :zone="zone")
	g(
		ref="toggle"
		role="button"
		:tabindex="plan.active ? 0 : -1"
		v-bind:aria-label="zone.name"
		v-bind:aria-controls="'zone-rows-' + zone.index"
		v-bind:aria-expanded="zone.active ? 'true' : 'false'"
		@click="toggleActive"
		@keydown.space="toggleActive"
		@keydown.enter="toggleActive"
		@focus.once="setToggleSize"
	)
		rect(ref="outline" vector-effect="non-scaling-stroke")
		//- <text> is needed for VoiceOver on iOS Safari to work at all
		text {{ zone.name }}
	g(
		v-bind:aria-hidden="zone.active ? 'false' : 'true'"
		:id="'zone-rows-' + zone.index"
		ref="container"
	)
		plan-row(v-for="r in zone.rows", :row="r", :key="r.index", :zone="zone", ref="rows")

</template>
<script>
import PlanRow from './PlanRow'
import PlanArea from './PlanArea'
import {mapGetters} from 'vuex'

export default {
	components: { PlanRow, PlanArea },
	props: {
		zone: Object,
		plan: Object,
	},
	computed: {
		...mapGetters(['seatInFocus']),
		transform () {
			return `translate(${this.zone.position.x}, ${this.zone.position.y})`
		},
	},
	watch: {
		'zone.active' (newValue) {
			// do not focus first available row if a seat has focus already
			if (!newValue || this.seatInFocus) return

			this.$refs.rows.every(row => {
				if (row.isTabable) {
					row.focus()
					return false
				}
				return true
			})
		}
	},
	methods: {
		toggleActive (event) {
			this.$store.commit('toggleActive', this.zone)
		},
		setToggleSize () {
			const bbox = this.$refs.container.getBBox()
			const outline = this.$refs.outline
			this.$refs.toggle.setAttribute('transform', `translate(${bbox.x},${bbox.y})`)
			outline.setAttribute('width', bbox.width)
			outline.setAttribute('height', bbox.height)
		},
		focus () {
			this.$refs.toggle.focus()
		},
	}
}
</script>
<style lang="stylus">
</style>
