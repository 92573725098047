<template lang="pug">
rect.selection-area(v-if="selecting", :x="Math.min(start.x, current.x) - 0.5", :y="Math.min(start.y, current.y) - 0.5", :width="Math.abs(start.x - current.x)", :height="Math.abs(start.y - current.y)")
</template>
<script>
import {
	positionInZone,
} from '../../lib/geometry'

export default {
	props: {
		offsetParent: SVGSVGElement,
		transform: Object,
		from: Object,
	},
	data () {
		return {
			selecting: false,
			start: {x: 0, y: 0},
			current: {x: 0, y: 0},
		}
	},
	mounted () {
		window.addEventListener('mousemove', this.mousemove)
		window.addEventListener('mouseup', this.mouseup, {once: true})

		const bbox = this.offsetParent.getBoundingClientRect()
		this.start = this.current = positionInZone(this.from.x - bbox.x, this.from.y - bbox.y, this.transform, null)
		this.selecting = true
	},
	destroyed () {
		window.removeEventListener('mousemove', this.mousemove)
		window.removeEventListener('mouseup', this.mouseup)
	},
	methods: {
		mousemove (e) {
			const bbox = this.offsetParent.getBoundingClientRect()
			this.current = positionInZone(e.clientX - bbox.x, e.clientY - bbox.y, this.transform, null)
		},
		mouseup (e) {
			window.removeEventListener('mousemove', this.mousemove)
			this.selecting = false
			this.$emit('select', {from: this.start, to: this.current, altKey: e.altKey})
		}
	}
}
</script>
<style lang="stylus">
	.selection-area
		vector-effect: non-scaling-stroke
		stroke-width: 1px
		fill: rgba(0, 0, 204, 0.15)
		stroke: rgba(0, 0, 204, 0.4)
		stroke-style: solid
</style>
