const hasSingles = (seats, isSeatAvailable) => {
	// Validate that the user does not create a situation where a single seat stays empty in the middle,
	// since nobody is going to buy that seat.
	const selectedGuids = seats.map(seat => seat.seat_guid)
	const selectedRows = [...new Set(seats.map(seat => seat.row))]
	let category = null
	// TODO: optimization group seats per row and abort if all selected seats of a row have been tested

	for (const row of selectedRows) {
		// For every row, we iterate over the seats and count the length of each "gap" of unselected seats.
		let gap = 2// We do not start with 0 since we do allow to leave single seats at the very edge of the row
		let gapStartedBySelected = false
		for (const [seati, seat] of Array.from(row.seats.entries())) {
			if (seat.category !== category) {
				// The category changed. We treat this like a new row and restart our counters;
				gap = 2
				gapStartedBySelected = false
			}
			category = seat.category
			if (!isSeatAvailable(seat)) {
				// This seat is unavailable, we reset our gap counter. If we previously found a gap of length one *and*
				// the gap was started by a selected seat, we fail.
				if (gap === 1 && gapStartedBySelected) {
					// Exception: This is allowed when there is a connected bunch of selected seats that are not preceded by
					// an available seat.
					let allowed = false
					for (let lookbehind = seati - 2; lookbehind >= 0; lookbehind--) {
						if (!isSeatAvailable(row.seats[lookbehind])) {
							allowed = true
							break
						} else if (!selectedGuids.includes(row.seats[lookbehind].seat_guid)) {
							allowed = false
							break
						}
					}
					if (!allowed) {
						return true// {msg: "Please do not leave single seats empty.", fixable: false};
					}
				}
				gap = 0
				gapStartedBySelected = false
			} else if (selectedGuids.includes(seat.seat_guid)) {
				// This seat is selected, we reset our gap counter. If we previously found a gap of length one.
				if (gap === 1) {
					// Exception: This is allowed when there is a connected bunch of selected seats that are not followed by
					// an available seat and the gap is not created by selected seats on both sides.
					let allowed = false
					for (let lookahead = seati + 1; lookahead < row.seats.length; lookahead++) {
						if (!isSeatAvailable(row.seats[lookahead])) {
							allowed = !gapStartedBySelected
							break
						} else if (!selectedGuids.includes(row.seats[lookahead].seat_guid)) {
							allowed = false
							break
						}
					}
					if (!allowed) {
						return true// {msg: "Please do not leave single seats empty.", fixable: false};
					}
				}
				gap = 0
				gapStartedBySelected = true
			} else {
				gap++
			}
		}
	}
	return false
}

const selectionCanBeOptimized = (seats, isSeatAvailable) => {
	const groups = splitSelectionIntoGroups(seats)
	for (const group of groups) {
		const better = proposeBetterGroup(seats, group, isSeatAvailable)
		if (better.offset !== group.offset) {
			return true
		}
	}
	return false
}

const optimizeSelection = (seats, isSeatAvailable) => {
	let maxruns = 100
	let newSeats = Object.values(seats).filter(seat => {
		return seat !== undefined
	})

	while (maxruns--) {
		let improved = false
		const groups = splitSelectionIntoGroups(newSeats)
		const selectedGuids = newSeats.map(seat => seat.seat_guid)
		for (const group of groups) {
			const better = proposeBetterGroup(newSeats, group, isSeatAvailable)
			if (better.offset !== group.offset) {
				const newSelected = better.seats.map(seat => seat.seat_guid)

				better.seats.filter(seat => !selectedGuids.includes(seat.seat_guid)).forEach(
					seat => {
						newSeats.push(seat)
					}
				)
				group.seats.filter(seat => !newSelected.includes(seat.seat_guid)).forEach(
					seat => {
						newSeats = newSeats.filter(s => s.seat_guid !== seat.seat_guid)
					}
				)
				improved = true
				break
			}
		}
		if (!improved) {
			break
		}
	}
	return newSeats
}

const splitSelectionIntoGroups = (seats) => {
	const selectedGuids = seats.map(seat => seat.seat_guid)
	const selectedRows = [...new Set(seats.map(seat => seat.row))]
	const groups = []
	for (const row of selectedRows) {
		let currentGroup = null
		let category = null
		for (const [seati, seat] of Array.from(row.seats.entries())) {
			if (seat.category !== category) {
				currentGroup = {seats: [], row, offset: -1}
				category = seat.category
			}
			if (!selectedGuids.includes(seat.seat_guid)) {
				currentGroup = {seats: [], row, offset: -1}
				continue
			}
			currentGroup.seats.push(seat)
			if (currentGroup.seats.length === 1) {
				currentGroup.offset = seati
				groups.push(currentGroup)
			}
		}
	}
	return groups
}

const penaltyForGroup = (group, seats, isSeatAvailable) => {
	const groupSelectedGuids = group.seats.map(seat => seat.seat_guid)
	const selectedGuids = seats.map(seat => seat.seat_guid)
	let penaltyLeft = 0
	let penaltyRight = 0
	let leftDone = false
	let rightDone = false
	let leftFound = false
	let rightFound = false
	let penalty = 0

	// TODO: seat available muss ins SEAT-Objekt verschoben werden
	const fillsGap = (
		(group.offset === 0 || !isSeatAvailable(group.row.seats[group.offset - 1])) &&
		(group.offset + group.seats.length === group.row.seats.length || !isSeatAvailable(group.row.seats[group.offset + group.seats.length]))
	)
	if (fillsGap) {
		return 0
	}

	// Assign penalty based on distance from starting point
	// for (const [seati, seat] of Array.from(group.row.seats.entries())) {
	for (const seat of group.row.seats) {
		if (seat.start_direction && seat.start_direction.includes('>') && !leftDone) {
			penaltyLeft = 0
			leftFound = true
		}
		if (groupSelectedGuids.includes(seat.seat_guid)) {
			if (!leftFound) {
				continue
			}
			leftDone = true
		} else {
			if (!leftDone) {
				penaltyLeft++
			}
		}
	}
	// for (const [seati, seat] of Array.from(group.row.seats.entries()).reverse()) {
	for (const seat of group.row.seats.slice().reverse()) {
		if (seat.start_direction && seat.start_direction.includes('<') && !rightDone) {
			penaltyRight = 0
			rightFound = true
		}
		if (groupSelectedGuids.includes(seat.seat_guid)) {
			if (!rightFound) {
				continue
			}
			rightDone = true
		} else {
			if (!rightDone) {
				penaltyRight++
			}
		}
	}
	if (leftFound && rightFound) {
		penalty = Math.min(penaltyLeft, penaltyRight)
	} else if (leftFound) {
		penalty = penaltyLeft
	} else if (rightFound) {
		penalty = penaltyRight
	}

	const singleKept = ((
	// Middle of row, single on the left
		group.offset > 1 &&
		group.row.seats[group.offset - 1].category === group.row.seats[group.offset].category &&
		group.row.seats[group.offset - 2].category === group.row.seats[group.offset].category &&
		isSeatAvailable(group.row.seats[group.offset - 1]) &&
		!selectedGuids.includes(group.row.seats[group.offset - 1].seat_guid) &&
		(!isSeatAvailable(group.row.seats[group.offset - 2]) || selectedGuids.includes(group.row.seats[group.offset - 2].seat_guid))
	) || (
	// Beginning of row, single on the left
		group.offset === 1 &&
		group.row.seats[group.offset - 1].category === group.row.seats[group.offset].category &&
		isSeatAvailable(group.row.seats[group.offset - 1]) &&
		!selectedGuids.includes(group.row.seats[group.offset - 1].seat_guid)
	) || (
	// Middle of the row, single on the right
		group.offset + group.seats.length < group.row.seats.length - 1 &&
		group.row.seats[group.offset + group.seats.length].category === group.row.seats[group.offset + group.seats.length - 1].category &&
		group.row.seats[group.offset + group.seats.length + 1].category === group.row.seats[group.offset + group.seats.length - 1].category &&
		isSeatAvailable(group.row.seats[group.offset + group.seats.length]) &&
		!selectedGuids.includes(group.row.seats[group.offset + group.seats.length].seat_guid) &&
		(!isSeatAvailable(group.row.seats[group.offset + group.seats.length + 1]) || selectedGuids.includes(group.row.seats[group.offset + group.seats.length + 1].seat_guid))
	) || (
	// End of the row, single on the right
		group.offset + group.seats.length === group.row.seats.length - 1 &&
		group.row.seats[group.offset + group.seats.length].category === group.row.seats[group.offset + group.seats.length - 1].category &&
		isSeatAvailable(group.row.seats[group.offset + group.seats.length]) &&
		!selectedGuids.includes(group.row.seats[group.offset + group.seats.length].seat_guid)
	))
	if (singleKept) {
		penalty += 100
	}

	return penalty
}

const proposeBetterGroup = (seats, group, isSeatAvailable) => {
	const groupSelectedGuids = group.seats.map(seat => seat.seat_guid)
	const selectedGuids = seats.map(seat => seat.seat_guid)
	let offset = 1
	let min = penaltyForGroup(group, seats, isSeatAvailable)

	const countPerCategory = {}
	// for (const [seati, seat] of Array.from(group.seats.entries())) {
	for (const seat of group.seats) {
		countPerCategory[seat.category] = (countPerCategory[seat.category] ? countPerCategory[seat.category] : 0) + 1
	}

	let argmin = group
	if (min === 0) {
		return group
	}

	const isValidGroup = function (proposal) {
		if (proposal.seats.filter(s => !isSeatAvailable(s)).length) {
			// Do not propose unavailable seats
			return false
		}
		if (proposal.seats.filter(s => selectedGuids.includes(s.seat_guid) && !groupSelectedGuids.includes(s.seat_guid)).length) {
			// Do not propose seats selected in other groups
			return false
		}

		// Do not propose seats with other categories
		const pCountPerCategory = {}
		// for (const [seati, seat] of Array.from(proposal.seats.entries())) {
		for (const seat of proposal.seats) {
			pCountPerCategory[seat.category] = (pCountPerCategory[seat.category] ? pCountPerCategory[seat.category] : 0) + 1
		}
		for (const cat in pCountPerCategory) {
			if (countPerCategory[cat] !== pCountPerCategory[cat]) {
				return false
			}
		}

		return true
	}

	while (group.offset - offset >= 0 || group.offset + group.seats.length + offset < group.row.seats.length) {
		if (group.offset - offset >= 0) {
			const shifted = {
				seats: group.row.seats.slice(group.offset - offset, group.offset - offset + group.seats.length),
				row: group.row,
				offset: group.offset - offset
			}
			if (isValidGroup(shifted)) {
				const shiftedIds = shifted.seats.map(s => s.seat_guid)
				const newSeats = group.row.seats.filter(s => (shiftedIds.includes(s.seat_guid) || (selectedGuids.includes(s.seat_guid) && !groupSelectedGuids.includes(s.seat_guid))))
				const penalty = penaltyForGroup(shifted, newSeats, isSeatAvailable)
				if (penalty < min) {
					min = penalty
					argmin = shifted
				}
				if (penalty === 0) {
					break
				}
			}
		}
		if (group.offset + group.seats.length + offset - 1 < group.row.seats.length) {
			const shifted = {
				seats: group.row.seats.slice(group.offset + offset, group.offset + offset + group.seats.length),
				row: group.row,
				offset: group.offset + offset
			}
			if (isValidGroup(shifted)) {
				const shiftedIds = shifted.seats.map(s => s.seat_guid)
				const newSeats = group.row.seats.filter(s => (shiftedIds.includes(s.seat_guid) || (selectedGuids.includes(s.seat_guid) && !groupSelectedGuids.includes(s.seat_guid))))
				const penalty = penaltyForGroup(shifted, newSeats, isSeatAvailable)
				if (penalty < min) {
					min = penalty
					argmin = shifted
				}
				if (penalty === 0) {
					break
				}
			}
		}
		offset++
	}

	return argmin
}

export {
	hasSingles,
	optimizeSelection,
	selectionCanBeOptimized,
}
