<template lang="pug">
.seating-cart
	section(v-if="selection.length", class="seating-cart-items")
		cart-item(v-for="(s, index) in selection"
			:key="index"
			:seat="s.seat"
			:item="s.item"
			:index="index+1"
			:valid="isValid"
			@remove="onSeatRemove"
			:gettext="gettext"
			ref="items"
		)
	p.seating-cart-hint(v-else-if="!loading") {{gettext("Please select a seat by clicking on it.")}}
</template>
<script>

import CartItem from 'components/cart/CartItem'

export default {
	components: {CartItem},
	props: {
		seatingPlan: {
			// CSS-selector for plan, usually #<id>
			type: String,
			required: false,
			default: 'pretix-seating-plan'
		},
		checkoutButton: {
			// CSS-selector for checkout-button, usually #<id>
			type: String,
			required: false,
			default: 'pretix-seating-checkout-button'
		},
		translation: {
			type: Object,
			required: false,
			default (rawProps) {
				return {}
			}
		},
	},
	data () {
		return {
			// Array of seat-Objects
			selection: [],
			// CustomElement/DOM-Node of pretix-seatingplan
			planElement: null,
			// currently methods are not exposed, so we need vueComponent of plan-DOMNode
			planComponent: null,
			isValid: true,
			loading: true,
		}
	},
	watch: {
		seatingPlan: {
			immediate: true,
			handler (newValue, oldValue) {
				window.customElements.whenDefined('pretix-seating-plan').then(() => {
					if (document.readyState === 'loading') {
						document.addEventListener('DOMContentLoaded', this.setPlan)
					} else {
						this.setPlan()
					}
				})
			}
		},
		checkoutButton: {
			immediate: true,
			handler (newValue, oldValue) {
				if (oldValue) {
					document.querySelector(oldValue)?.removeEventListener(this.onValidate)
				}
				window.customElements.whenDefined('pretix-seating-checkout-button').then(() => {
					if (document.readyState === 'loading') {
						return new Promise(function (resolve, reject) {
							document.addEventListener('DOMContentLoaded', resolve)
						})
					}
				}).then(() => {
					document.querySelector(newValue)?.addEventListener('validate', this.onValidate)
				})
			}
		}
	},
	methods: {
		gettext (s) {
			return this.translation[s] || s
		},
		onSeatRemove (e) {
			this.planComponent.unselectSeat(e.seat.seat_guid)
		},
		setPlan () {
			this.planElement = document.querySelector(this.seatingPlan)
			if (!this.planElement) return
			this.planComponent = this.planElement.vueComponent

			this.planElement.addEventListener('load', (e) => {
				this.planComponent = this.planElement?.vueComponent
				this.loading = false
			})
			this.planElement.addEventListener('error', (e) => {
				console.error('plan.error-event', e)
			})
			this.planElement.addEventListener('select', (e) => {
				// currently only complete selection is given every time an update happens
				this.selection = e.detail[0]
			})
		},
		getSelectedSeats () {
			const seats = []
			this.$refs.items.forEach(i => {
				seats.push({seat: i.seat.seat_guid, item: i.item.id, variation: i.variation?.id || null })
			})
			return seats
		},
		onValidate (e) {
			this.isValid = e.detail.valid
		},
	}
}
</script>
<style lang="stylus">
</style>
