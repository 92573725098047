<template lang="pug">
g(:transform="transform", class="row")
	text(v-if="rowNumberStart", :x="rowNumberStart.x", :y="rowNumberStart.y", dy=".3em", fontSize="12px", :text-anchor="rowNumberStart.textAnchor", :transform="rowNumberStart.transform", fill="#888", aria-hidden="true") {{row.row_number}}
	text(v-if="rowNumberEnd", :x="rowNumberEnd.x", :y="rowNumberEnd.y", dy=".3em", fontSize="12px", :text-anchor="rowNumberEnd.textAnchor", :transform="rowNumberEnd.transform", fill="#888", aria-hidden="true") {{row.row_number}}
	g(
		ref="toggle"
		role="button"
		:tabindex="isTabable ? 0 : -1"
		v-bind:aria-label="label"
		v-bind:aria-controls="'row-seats-' + zone.index + '-' + row.index"
		v-bind:aria-expanded="row.active ? 'true' : 'false'"
		@click="toggleActive"
		@keydown.space="toggleActive"
		@keydown.enter="toggleActive"
		@focus.once="setToggleSize"
	)
		rect(ref="outline" vector-effect="non-scaling-stroke")
		//- <text> is needed for VoiceOver on iOS Safari to work at all
		text {{ label }}
	g(
		v-bind:aria-hidden="row.active ? 'false' : 'true'"
		:id="'row-seats-' + zone.index + '-' + row.index"
		ref="container"
	)
		plan-seat(v-for="s in row.seats", :seat="s", :key="s.uuid", :zone="zone", :row="row", ref="seats")

</template>
<script>
import PlanSeat from './PlanSeat'
import {mapGetters} from 'vuex'

const round = (fl, places) => Number(fl.toFixed(places || 0))

const getRowNumber = (row, end) => {
	if (row.row_number_position !== 'both' && row.row_number_position !== (end ? 'end' : 'start')) return null
	if (row.seats.length < 2) return null

	// To determine the *position* of the row number, we calculate the vector from the second-outmost
	// seat to the outmost seat and extend it by distFactor times the radius of the outmost seat.
	const distFactor = 2 // Determined by "what looks good?" with lots of real plans
	const idxedge = end ? row.seats.length - 1 : 0
	const idxcomp = end ? row.seats.length - 2 : 1
	const linevec = [
		row.seats[idxedge].position.x - row.seats[idxcomp].position.x,
		row.seats[idxedge].position.y - row.seats[idxcomp].position.y,
	]
	const linelen = Math.sqrt(linevec[0] * linevec[0] + linevec[1] * linevec[1])
	const linevecNormed = [
		round(linevec[0] / linelen, 8), // prevent floating point issues
		round(linevec[1] / linelen, 8),
	]

	const x = row.seats[idxedge].position.x + linevecNormed[0] * distFactor * (row.seats[idxedge].radius || 10)
	const y = row.seats[idxedge].position.y + linevecNormed[1] * distFactor * (row.seats[idxedge].radius || 10)

	// To support upside-down rows properly, we base the text-anchor on the vector, not on the end flag
	let flipped = linevecNormed[0] < 0

	// To determine the *rotation* of the row number, we compute the angle between the vector from the
	// second-outmost seat to the outmost seat and the vertical axis.
	// We then rotate the result 90° in the direction of the vertical axis, because we want our vector
	// to be the text's length axis, not it's size axis.
	// We use atan(), which only returns results between -90° and 90°. This is nice, because it mean
	// we'll never render text which is upside down, but alwas favor the direction most suitable to read.
	// The exception is if we have a perfectly vertical row, in which case it is unclear whether -90° or 90°
	// is easier to read. Visually, it looks nice if the text is rotated in the way that it is correct
	// "looking from the stage". We don't know where the stage is, so we're assuming the order of the seats
	// in the row is "left to right" (looking from the seat to the stage), because that's the most likely way
	// to create the row.
	let transform = ''
	let theta = -Math.atan(linevecNormed[0] / linevecNormed[1])
	theta -= (Math.sign(theta) >= 0 ? 1 : -1) * Math.PI / 2 // Do not use Math.sign directly to multiply because Math.sign(0)==0
	if (Math.abs(Math.abs(theta) - Math.PI / 2) < 0.0001) { // 90°
		if ((linevecNormed[1] > 0) !== end) { // right of stage
			theta += Math.PI
		}
		flipped = end
	}
	if (Math.abs(theta) > 0.0001) {
		transform = `rotate(${theta / Math.PI * 180}, ${x}, ${y})`
	}

	return {
		x,
		y,
		textAnchor: flipped ? 'end' : 'start',
		transform,
	}
}

export default {
	components: { PlanSeat },
	props: {
		row: Object,
		zone: null,
	},
	computed: {
		...mapGetters('event', ['isSeatAvailable', 'getItem']),
		...mapGetters('ui', ['_']),
		...mapGetters(['seatInFocus']),
		transform () {
			return `translate(${this.row.position.x}, ${this.row.position.y})`
		},
		rowNumberStart () {
			return getRowNumber(this.row, false)
		},
		rowNumberEnd () {
			return getRowNumber(this.row, true)
		},
		label () {
			const label = this.row.row_label || this._('Row %s')
			return label.replace('%s', this.row.row_number).trim() + (this.description ? ', ' + this.description : '')
		},
		htmlId () {
			return 'row-' + this.zone.index + '-' + this.row.index
		},
		isTabable () {
			return this.zone.active
		},
		description () {
			const availableSeats = this.row.seats.filter(seat => this.getItem(seat.category) && this.isSeatAvailable(seat))
			return availableSeats.length
				? this._('%(available)s of %(total)s seats available')
					.replace('%(available)s', availableSeats.length)
					.replace('%(total)s', this.row.seats.length)
				: this._('no seats available')
		},
	},
	watch: {
		'row.active' (newValue) {
			// do not focus first available seat if another seat has focus already
			if (!newValue || this.seatInFocus) return

			this.$refs.seats.every(seat => {
				if (seat.isTabable) {
					seat.$el.focus()
					return false
				}
				return true
			})
		}
	},
	methods: {
		toggleActive (event) {
			this.$store.commit('toggleActive', this.row)
		},
		focus () {
			this.$refs.toggle.focus()
		},
		setToggleSize () {
			const bbox = this.$refs.container.getBBox()
			const outline = this.$refs.outline
			this.$refs.toggle.setAttribute('transform', `translate(${bbox.x},${bbox.y})`)
			outline.setAttribute('width', bbox.width)
			outline.setAttribute('height', bbox.height)
		},
	}
}
</script>
<style lang="stylus">
</style>
