export default {
	namespaced: true,
	state: {
		items: [],
	},
	getters: {
		items (state) {
			return state.items
		},
		isFiltered: (state) => (item) => {
			return item ? state.items.includes(item.id) : false
		},
	},
	mutations: {
		// save item.id, as on event reload, it replaces items with new objects
		add (state, { item }) {
			if (!state.items.includes(item.id)) {
				// force a replace instead of in-place .push as we need oldValue in ItemsFilter.watcher
				state.items = state.items.concat([item.id])
			}
		},
		remove (state, { item }) {
			if (state.items.includes(item.id)) {
				// force a replace instead of in-place .splice as we need oldValue in ItemsFilter.watcher
				state.items = state.items.filter(i => i !== item.id)
			}
		},
	}
}
