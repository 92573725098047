<template lang="pug">
g(ref="seat", @click="toggleSelected", @mouseenter="mouseenter", @mouseleave="unfocus", @keydown.enter="toggleSelected", @keydown.space.prevent="toggleSelected", @focus="focus", @blur="unfocus", :class="classObject", :tabindex="isTabable ? 0 : -1" v-bind:aria-label="ariaLabel" :role="ariaRole" v-bind:aria-hidden="ariaHidden" v-bind:aria-checked="ariaChecked")
	circle(class="focusring", :cx="seat.position.x", :cy="seat.position.y", :r="radius+3", :stroke="categoryColor")
	circle(class="area", :cx="seat.position.x", :cy="seat.position.y", :r="radius", :fill="categoryColor", :stroke="categoryColor")
	text(:x="seat.position.x" :y="seat.position.y" dy=".35em") {{ seatLabel }}

</template>
<script>
import {contrast, hex2rgb} from '../../lib/colors'
import {mapGetters} from 'vuex'

export default {
	props: {
		seat: Object,
		row: Object,
		zone: Object,
	},
	computed: {
		// eslint-disable-next-line no-undef
		...mapGetters('plan', ['categories', 'getCategoryByName']),
		...mapGetters('event', ['isSeatAvailable', 'getItem', 'getFlag']),
		...mapGetters('filter', {isItemFiltered: 'isFiltered'}),
		...mapGetters('ui', ['_']),
		...mapGetters(['seatInFocus', 'isDragging', 'isAreaSelecting']),
		classObject () {
			return {
				seat: true,
				selected: this.isSelected,
				available: this.isAvailable,
				filtered: this.isFiltered,
				hiddenLabel: !this.flag,
				darkBg: contrast([0, 0, 0], hex2rgb(this.category.color)) < 5,
				uncategorized: !this.category,
			}
		},
		category () {
			return this.getCategoryByName(this.seat.category)
		},
		item () {
			return this.getItem(this.seat.category)
		},
		isSelected () {
			return this.seat && this.seat.selected
		},
		isAvailable () {
			// seat has a buyable product and seat is not taken yet
			return this.item && this.isSeatAvailable(this.seat)
		},
		isTabable () {
			return this.row.active && this.isAvailable && !this.isFiltered
		},
		isFiltered () {
			return this.isItemFiltered(this.item)
		},
		ariaLabel () {
			if (!this.isAvailable) {
				return null
			}
			const label = this.seat.row.seat_label || this._('Seat %s')
			return label.replace('%s', this.seat.seat_number).trim()
		},
		ariaRole () {
			return this.isAvailable ? 'checkbox' : null
		},
		ariaHidden () {
			return this.isAvailable ? 'false' : 'true'
		},
		ariaChecked () {
			if (!this.isAvailable) {
				return null
			}
			return this.isSelected ? 'true' : 'false'
		},
		radius () {
			if (this.seat.radius !== undefined) {
				return this.seat.radius
			} else {
				return 10
			}
		},
		flag () {
			return this.getFlag(this.seat)
		},
		seatLabel () {
			return this.flag || this.seat.seat_number
		},
		categoryColor () {
			const catIndex = this.categories.findIndex(c => c.name === this.seat.category)
			return `var(--category-${catIndex}-color, #666666)`
		},
	},
	methods: {
		toggleSelected (event) {
			if (this.isAreaSelecting) {
				return
			}
			if (this.isFiltered) {
				// a click on a filtered seat/item activates item again
				this.$store.commit('filter/remove', { item: this.item })
				this.$store.commit('focusSeat', {seat: this.seat, element: this.$refs.seat})
			} else if (this.isAvailable) {
				this.$store.commit(this.seat.selected ? 'unselect' : 'select', {seats: [this.seat]})
				this.$store.commit('activate', this.zone)
				this.$store.commit('activate', this.row)
				// this.row.active = true
				// this.zone.active = true
			}
		},
		mouseenter (event) {
			if (!this.isDragging && !this.isFiltered) {
				// TODO: mouseenter is also triggered by touch and overwrites the focus event so
				// that on mobile the tooltip is placed near the touch point and not the touched element.
				// This is due to TooltipSeat not updating the tooltip, when seatInFocus is the same.
				// This is done to not have the tooltip stay with the mouse-cursor and not jump to the
				// element’s position when using the mouse and clicking the seat.
				// Maybe use a debounced mousemove-event? That way one needs to move the mouse over the seat
				// and then wait a little – just like the system-tooltip for title-attribute. So debounce
				// mousemove and wait for mouse-cursor to be stopped and kept still
				// on mouseleave that debounce-timer needs to be cleared as well
				// Alternative might be to use touchstart to check if we need to trigger mouseenter-event?
				this.$store.commit('focusSeat', {seat: this.seat})
			}
		},
		focus (event) {
			if (this.isAvailable && !this.isFiltered) {
				this.$store.commit('focusSeat', {seat: this.seat, element: this.$refs.seat})
			} else {
				this.$refs.seat.blur()
			}
		},
		unfocus (event) {
			this.$store.commit('unfocusSeat', {seat: this.seat})
		},
	},
}
</script>
<style lang="stylus">
	.seat.available
		cursor: pointer
	.seat .area
		stroke-width: 1px
	.uncategorized .area
		fill: #fff
		stroke: #666
	.seat:not(.available):not(.filtered) .area
		fill: #d9d9d9
		stroke: #d9d9d9
	.selected .area
		fill: #fff

	.seat text
		font-size: 10px
		text-anchor: middle
		fill: #000
	.darkBg text
		fill: #fff
	.selected text
		fill: #666

	.hiddenLabel text
		display: none
	.available:hover text, .available:focus text, .selected text
		display: block
	.filtered:not(.selected) text
		display: none !important

	.focusring
		visibility: hidden
		fill: none
		stroke-width: 2px
	.seat:not(.available) .focusring,
	.uncategorized .focusring
		stroke: #d9d9d9

	.seat:focus
		outline: none

		.focusring
			visibility: visible
</style>
