import Vue from 'vue'
import Vuex from 'vuex'
import plan from './plan'
import event from './event'
import filter from './filter'
import ui from './ui'
import * as d3 from 'd3'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// View
		zoomTransform: d3.zoomTransform({k: 1, x: 0, y: 0}),

		isDragging: false,
		isAreaSelecting: false,

		// Selection
		ignoreNextSelection: false,
		selection: [],

		tooltip: Object,
		seatInFocus: Object,

		instructions: '',
	},
	getters: {
		selection (state) {
			return state.selection
		},
		zoomTransform (state) {
			return state.zoomTransform
		},
		seatInFocus (state) {
			return state.seatInFocus ? state.seatInFocus.seat : null
		},
		isDragging (state) {
			return state.isDragging
		},
		isAreaSelecting (state) {
			return state.isAreaSelecting
		},
		instructions (state) {
			return state.instructions
		},
	},
	mutations: {
		instructions (state, { msg }) {
			state.instructions = msg
		},
		clearInstructions (state) {
			state.instructions = ''
		},
		setTranslation (state, { translation }) {
			this.commit('ui/setTranslation', {translation})
		},
		loadPlan (state, { data }) {
			this.commit('plan/loadPlan', {plan: data})
			state.selection = []
		},
		loadEvent (state, { data }) {
			this.commit('event/loadEvent', {event: data})
			// TODO: remove seats that are not available anymore from state.selection here?
		},
		tooltip (state, {label, description, element}) {
			state.tooltip = {label, description, element}
		},
		clearTooltip (state) {
			state.tooltip = {}
		},
		focusSeat (state, {seat, element}) {
			state.seatInFocus = {seat, element}
		},
		unfocusSeat (state, {seat}) {
			// only unfocus seat if it is still the one who has focus
			if (state.seatInFocus && state.seatInFocus.seat === seat) {
				state.seatInFocus = null
			}
		},
		select (state, {seats}) {
			seats = seats.filter(seat => !seat.selected)
			seats.forEach(seat => Vue.set(seat, 'selected', true))
			state.selection = state.selection.concat(seats)
		},
		unselect (state, {seats}) {
			seats = seats.filter(seat => seat.selected)
			seats.forEach(seat => Vue.set(seat, 'selected', false))
			state.selection = state.selection.filter(s => !seats.includes(s))
		},
		clearSelection (state) {
			state.selection = []
		},
		setZoomTransform (state, t) {
			state.zoomTransform = t
		},
		startDrag (state) {
			state.isDragging = true
		},
		endDrag (state) {
			state.isDragging = false
		},
		startAreaSelect (state) {
			state.isAreaSelecting = true
		},
		endAreaSelect (state) {
			state.isAreaSelecting = false
		},
		toggleActive (state, obj) {
			Vue.set(obj, 'active', !obj.active)
		},
		activate (state, obj) {
			Vue.set(obj, 'active', true)
		}
	},
	modules: {
		plan,
		event,
		filter,
		ui,
	},
})
