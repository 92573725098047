<template lang="pug">
div(:class="classObject")
	p.items-filter-head
		button.toggle(type="button" @click="toggleList" ref="toggle" aria-expanded="false" aria-controls="items-filter-fieldset" :title="_('Filter seats by ticket type')")
			svg(id="filter-icon" svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32")
				rect(id="filter-icon-box" x="3.5" y="3.5" width="10" height="10" rx="1" ry="1" fill="none")
				polyline(id="filter-icon-check" points="5.5 8 8 10.5 12 6.5" stroke-width="1.5")
				use(href="#filter-icon-box" x="0" y="13")
				use(id="filter-icon-check-2" href="#filter-icon-check" x="0" y="13")
				polyline(id="filter-icon-pointer" points="17 14 22 18 27 14" stroke-width="2" vector-effect="non-scaling-stroke")
		span.item-filter-item
			input(ref="checkall" type="checkbox" @change="onItemFilterAllNone" :checked="isItemFilterEmpty" id="item-filter-all-none")
			label(for="item-filter-all-none") {{ _('Select all') }}

	fieldset#items-filter-fieldset
		legend {{ _('Filter seats by ticket type') }}
		ul.items-filter-items(ref="itemlist")
			items-filter-item(v-for="i in items" :item="i" :key="i.id")
</template>
<script>
import {mapGetters} from 'vuex'
import ItemsFilterItem from './ItemsFilterItem'

export default {
	components: { ItemsFilterItem },
	data () {
		return {
			isOpen: false,
			resizeTimeout: null,
		}
	},
	computed: {
		...mapGetters('ui', ['_']),
		...mapGetters('event', ['items']),
		...mapGetters('filter', {filteredItems: 'items'}),
		classObject () {
			return {
				'items-filter': true,
				'filter-empty': this.isItemFilterEmpty,
				'filter-full': this.isItemFilterFull,
				open: this.isOpen,
			}
		},
		isItemFilterEmpty () {
			return this.filteredItems.length === 0
		},
		isItemFilterFull () {
			return this.filteredItems.length === this.items.length
		}
	},
	watch: {
		filteredItems (newValue, oldValue) {
			this.$refs.checkall.indeterminate = newValue.length > 0 && newValue.length < this.items.length
		},
	},
	mounted () {
		this.$el.addEventListener('click', this.onClick)
	},
	destroyed () {
		this.$el.removeEventListener('click', this.onClick)
	},
	methods: {
		toggleList (event) {
			this.isOpen = !this.isOpen
			this.$refs.toggle.setAttribute('aria-expanded', this.isOpen)
			if (this.isOpen) {
				this.calcMaxHeight()
				window.addEventListener('click', this.onBgClick, { once: true })
			} else {
				window.removeEventListener('click', this.onBgClick)
			}
		},
		onClick (event) {
			// do not bubble events as bgClick on window.click would trigger an immediate close
			event.stopImmediatePropagation()
		},
		onBgClick (event) {
			this.toggleList(event)
		},
		onItemFilterAllNone (event) {
			this.items.forEach(item => {
				this.$store.commit(event.target.checked ? 'filter/remove' : 'filter/add', { item })
			})
		},
		calcMaxHeight () {
			const bbox = this.$el.parentNode.getBoundingClientRect()
			this.$refs.itemlist.style.maxHeight = 'calc(' + bbox.height + 'px - 5em)'
		},
	},
}
</script>
<style lang="stylus">
	.items-filter
		position: absolute
		top: 1.15em
		right: 1.15em

		fieldset, legend
			display: none
			border: none
			padding: 0
			margin: 0

	.items-filter-head
		margin: .35em
		padding: 0
		display: flex
		gap: .5em
		align-items: center
		flex-direction: row-reverse;

		.item-filter-item
			display: none
			padding: 0
			flex: 1

			label
				flex: 1
				font-weight: bold

	.open
		max-width: calc(100% - 1.7em)
		padding-bottom: .25em
		background-color: #fff
		--outer-border-radius: calc(var(--border-radius, 0) * 2)
		border-radius: var(--outer-border-radius)
		box-shadow: 2px 3px 6px rgba(0,0,0,0.25)

		.items-filter-head
			margin: 0
			padding: .35em .35em .35em .8em
			background-color: #e5e5e5
			border-radius: var(--outer-border-radius) var(--outer-border-radius) 0 0

			.item-filter-item
				display: flex

		fieldset
			display: block

	.items-filter-items
		margin: 0
		padding: 0 .8em 0 .8em
		list-style: none
		overflow-y: auto

	.toggle
		width: 32px
		height: 32px
		padding: 0
		white-space: nowrap
		border: 1px solid rgba(0,0,0,0.25)
		border-radius: var(--border-radius, 0)
		cursor: pointer
		background-color: rgba(236,236,236,0.8)

	.toggle:hover
		background-color: #f9f9f9

	.open .toggle
		background-color: #f9f9f9

	#filter-icon
		width: 32px
		height: 32px
		stroke: #555
		stroke-linecap: butt
		stroke-linejoin: miter
		fill:none

	#filter-icon-check-2
		display: none
	.filter-full #filter-icon-check
		display: none
	.filter-empty #filter-icon-check-2
		display: block

	#filter-icon-pointer
		transform-origin: 22px 15px
		transform: scale(1, 1)
		transition: transform .5s
	.open #filter-icon-pointer
		transform: scale(1, -1)

</style>
