<template lang="pug">
article(class="seating-cart-item")
	input(v-if="valid"
		type="hidden"
		:name="inputName"
		:value="seat.seat_guid"
		v-bind:data-item="item.id"
	)
	div.seating-item-name
		h4 {{item.name}}
			i.fa.fa-ticket(aria-hidden="true")
		button.btn.btn-link(@click="remove", type="button", v-bind:aria-label="removeButtonLabel")
			i.fa.fa-trash(aria-hidden="true")
	p.seating-item-seat {{seatLabelZoneFirst}}
	p.seating-item-description(v-if="item.description", v-html="item.description")
	p.seating-item-variation(v-if="item.variations.length > 1")
		select(@change="change", ref="select", :name="selectName", v-bind:aria-label="selectVariationLabel", v-bind:aria-describedby="'desc_'+selectName", class="form-control")
			option(v-for="v in item.variations", :value="v.id", :selected="v.id == variation.id") {{v.name}} - {{v.price}}
	p.seating-item-variation-description(v-if="variation.description", :id="'desc_'+selectName", v-html="variation.description")
	p.seating-item-price(v-if="originalPrice")
		del
			span.sr-only {{ labelOriginalPrice }}
			| {{ originalPrice }}
		| &#x20;
		ins
			span.sr-only {{ labelNewPrice }}
			| {{ price }}
	p.seating-item-price(v-else-if="item.variations.length < 2") {{ price }}

</template>
<script>
export default {
	props: {
		index: Number,
		seat: Object,
		item: Object,
		valid: Boolean,
		gettext: Function,
	},
	data () {
		return {
			variation: Object,
		}
	},
	computed: {
		inputName () {
			return 'seat_0' + (this.variation && this.variation.id ? '_' + this.variation.id : '')
		},
		selectName () {
			return 'input_variation_' + this.index
		},
		zoneLabel () {
			return this.seat.zone.name
		},
		rowLabel () {
			return (this.seat.row.row_label || this.gettext('Row %s')).replace('%s', this.seat.row.row_number)
		},
		seatLabel () {
			return (this.seat.row.seat_label || this.gettext('Seat %s')).replace('%s', this.seat.seat_number)
		},
		seatLabelZoneFirst () {
			return this.gettext('%(zone)s - %(row)s - %(seat)s')
				.replace('%(zone)s', this.zoneLabel)
				.replace('%(row)s', this.rowLabel)
				.replace('%(seat)s', this.seatLabel)
		},
		seatLabelSeatFirst () {
			return this.gettext('%(seat)s, %(row)s, %(zone)s')
				.replace('%(zone)s', this.zoneLabel)
				.replace('%(row)s', this.rowLabel)
				.replace('%(seat)s', this.seatLabel)
		},
		removeButtonLabel () {
			return this.gettext('Remove %s from selection').replace('%s', this.seatLabelSeatFirst)
		},
		selectVariationLabel () {
			return this.gettext('Select variation for %s').replace('%s', this.seatLabelSeatFirst)
		},
		price () {
			return this.item.variations.length ? this.variation.price : this.item.price
		},
		originalPrice () {
			return this.item.variations.length ? this.variation.original_price : this.item.original_price
		},
		labelOriginalPrice () {
			return this.gettext('Original price:')
		},
		labelNewPrice () {
			return this.gettext('New price:')
		},
	},
	beforeMount () {
		// TODO: pre-select correct variation on seat?
		if (this.item.variations.length) this.variation = this.item.variations[0]
	},
	methods: {
		remove (event) {
			this.$emit('remove', {seat: this.seat})
		},
		change (event) {
			this.variation = this.item.variations[this.$refs.select.selectedIndex]
		},
	}
}
</script>
<style lang="stylus">

</style>
