<template lang="pug">
.tooltip(v-if="tooltip.label || tooltip.description")
	b(v-if="tooltip.label") {{ tooltip.label }}
	br(v-if="tooltip.label && tooltip.description")
	| {{ tooltip.description }}

</template>
<script>

import {mapState} from 'vuex'

export default {
	computed: {
		...mapState(['tooltip']),
	},
	watch: {
		tooltip: function (t) {
			// if no target-element is given, follow mouse (used for e.g. onhover vs. onfocus)
			if ((t.label || t.description) && !t.element) {
				window.addEventListener('mousemove', this.move)
				return
			}

			// otherwise do not follow mouse anymore
			window.removeEventListener('mousemove', this.move)
			if (!t.label && !t.description) return

			// this.$el is not available until nextTick
			this.$nextTick(() => {
				// always hide tooltip until we have a position - either mouse or element
				this.$el.style.display = 'block'
				if (!this.$el.offsetParent) return

				const bb = t.element.getBoundingClientRect()
				const offsetBbox = this.$el.offsetParent.getBoundingClientRect()
				// TODO: right-align tooltip if language is rtl?
				let x = bb.right - offsetBbox.x
				const y = Math.floor(bb.top - offsetBbox.y + (bb.height / 2) - (this.$el.offsetHeight * 0.3))

				if (x + this.$el.offsetWidth > window.innerWidth) {
					x = bb.left - this.$el.offsetWidth
					this.$el.classList.add('swapside')
				} else {
					this.$el.classList.remove('swapside')
				}
				this.$el.style.transform = `translate(${x}px, ${y}px)`
			})
		}
	},
	destroyed () {
		window.removeEventListener('mousemove', this.move)
	},
	methods: {
		move (event) {
			// always hide tooltip until we have a position - either mouse or element
			// due to mousemove being bound on demand, initially tooltip could have no position
			// when following the mouse
			this.$el.style.display = 'block'

			// TODO: right-align tooltip if language is rtl?
			let x = event.clientX + 12
			const y = Math.floor(event.clientY - (this.$el.offsetHeight * 0.28))
			if (x + this.$el.offsetWidth > window.innerWidth) {
				x = x - this.$el.offsetWidth - 24
				this.$el.classList.add('swapside')
			} else {
				this.$el.classList.remove('swapside')
			}

			const offsetBbox = this.$el.offsetParent.getBoundingClientRect()
			this.$el.style.transform = `translate(${x - offsetBbox.x}px, ${y - offsetBbox.y}px)`
		},
	}
}
</script>
<style lang="stylus">
	.tooltip
		display: none /*always hide tooltip until we have a position - either mouse or element*/
		position: absolute
		top: 0
		left: 0
		background: rgba(0,0,0,0.7)
		color: #fff
		border-radius: 3px;
		padding: 3px 8px
		white-space: pre-line
		pointer-events: none

		&:before
			content: ""
			display: block
			position: absolute
			top: 22%
			left: -5px
			width: 0
			height: 0
			border-top: 5px solid transparent
			border-bottom: 5px solid transparent
			border-right: 5px solid rgba(0,0,0,0.7)

		&.swapside:before
			left: auto
			right: -5px
			border-right: none
			border-left: 5px solid rgba(0,0,0,0.7)
</style>
