export default {
	namespaced: true,
	state: {
		_event: {},
	},
	getters: {
		items (state) {
			return state._event.items
		},
		isSeatAvailable: (state) => (seat) => {
			// TODO: cache availability of seat on seat? keep in mind to stale cache when refreshing
			// when the plan is drawn for the first time, its availability is checked for every seat
			if (!seat) return false
			return state._event.available_seats.includes(seat.seat_guid)
		},
		getItem: (state) => (category) => {
			if (!state._event.items) return null
			return state._event.items.find(item => item.categories.includes(category))
		},
		getItemById: (state) => (id) => {
			if (!state._event.items) return null
			return state._event.items.find(item => item.id === id)
		},
		// TODO: make prop not method, see validation in Checkout
		validation: (state) => () => {
			return state._event.validation
		},
		getFlag: (state) => (seat) => {
			return state._event.flags?.[seat.seat_guid]
		},
		getTooltip: (state) => (seat) => {
			return state._event.tooltips?.[seat.seat_guid]
		},
		isSoldOut (state) {
			return state._event.items?.length === 0 || state._event.available_seats?.length === 0
		},
	},
	mutations: {
		loadEvent (state, { event }) {
			state._event = event
		},
	},
	actions: {

	}
}
