import Vue from 'vue'
import SeatingCheckoutButton from './SeatingCheckoutButton.vue'

window.customElements.define('pretix-seating-checkout-button',
	class extends HTMLElement {
		connectedCallback () {
			if (!this.isConnected) return

			const seatingPlan = this.getAttribute('seating-plan') || 'pretix-seating-plan'
			let translation = {}
			const settings = this.querySelector('script')?.textContent
			if (settings) {
				try {
					translation = JSON.parse(settings).translation || {}
				} catch (e) {
					console.error(e)
				}
			}

			// create div below customElement instead of Vue replacing it
			const el = document.createElement('div')
			this.appendChild(el)
			// eslint-disable-next-line no-unused-vars
			const app = new Vue({
				el,
				render: h => h(SeatingCheckoutButton, {props: { seatingPlan, translation }}),
			})
			app.$on('validate', (e) => {
				this.dispatchEvent(new CustomEvent('validate', { detail: e }))
			})
		}
	}
)
