
export default {
	namespaced: true,
	state: {
		_plan: {},
		_seatsByGuid: {},
	},
	getters: {
		plan (state) {
			return state._plan
		},
		planSize (state) {
			return state._plan.size
		},
		categories (state) {
			return state._plan.categories
		},
		getCategoryByName: (state) => (category) => {
			return state._plan.categories.find(c => c.name === category) || {name: '', color: '#d9d9d9'}
		},
		getSeatByGuid: (state) => (guid) => {
			return state._seatsByGuid[guid]
		}
	},
	mutations: {
		loadPlan (state, { plan }) {
			// plan = JSON.parse(plan) // Ensure there are no references left
			state._seatsByGuid = {}
			plan.active = false
			let nrOfZones = 0
			for (const z of plan.zones) {
				z.active = false
				z.index = nrOfZones++
				let nrOfRows = 0
				for (const r of z.rows) {
					r.active = false
					r.zone = z
					r.index = nrOfRows++
					for (const s of r.seats) {
						s.row = r
						s.zone = z
						s.selected = false
						state._seatsByGuid[s.seat_guid] = s
					}
				}
			}

			state._plan = plan
		},
	},
	actions: {

	}
}
