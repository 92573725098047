export default {
	namespaced: true,
	state: {
		translation: {},
	},
	getters: {
		_: (state) => (s) => {
			return state.translation[s] || s
		},
	},
	mutations: {
		setTranslation (state, { translation }) {
			state.translation = translation
		},
	}
}
