<template lang="pug">
transition(name="fade")
	.instructions(v-if="instructions")
		p(v-html="instructions")
</template>
<script>
import {mapGetters} from 'vuex'

export default {
	data () {
		return {
			fadeoutTimeout: null,
		}
	},
	computed: {
		...mapGetters(['instructions']),
	},
	/*
	watch: {
		'$store.state.instructions': function (newValue, oldValue) {
			// auto-hide instructions after 2.5s
			if (this.fadeoutTimeout) {
				window.clearTimeout(this.fadeoutTimeout)
			}
			if (newValue) {
				this.fadeoutTimeout = window.setTimeout(() => this.$store.commit('clearInstructions'), 2500)
			}
		}
	},
	*/
}
</script>
<style lang="stylus">
	.fade-enter-active, .fade-leave-active
		transition: opacity .5s

	.fade-enter, .fade-leave-to
		opacity: 0

	.instructions
		font-size: 1em
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background: rgba(0,0,0,.8)
		color: #fff
		font-weight: bold
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		text-align: center
		touch-action: pan-x, pan-y
		pointer-events: none

		kbd
			border-width: 1px 2px 2px 1px
			border-style: solid
			border-radius: 2px
			display: inline-block
			padding: 0 0.25em
			margin: 0 0.25em
			background-color: rgba(255,255,255,0.2)
			box-shadow: 1px 1px 2px #fff
</style>
