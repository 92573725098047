<template lang="pug">
#app
	link(rel="stylesheet", type="text/css", :href="styleSrc")
	div(hidden)
		slot
	plan-view(v-if="!loading && !errored", :scrollZoomWithModifier="scrollZoomWithModifier", :panWithModifier="panWithModifier")
	p#loading(v-if="loading")
		<svg viewBox="0 0 16 16" width="256" height="256" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2"><title>{{_("Loading …")}}</title><path d="M11.95 2.547a2.29 2.29 0 0 1-2.286 2.286 2.29 2.29 0 0 1-2.285-2.286A2.289 2.289 0 0 1 9.664.262a2.29 2.29 0 0 1 2.286 2.285Zm4.571-.973a.325.325 0 0 0-.259-.321l-1.633-.25a4.927 4.927 0 0 0-.367-.875c.304-.42.634-.813.938-1.223a.336.336 0 0 0 .071-.206.3.3 0 0 0-.062-.196c-.375-.527-1-1.081-1.473-1.518a.351.351 0 0 0-.224-.089c-.08 0-.16.026-.214.08l-1.268.955a5.193 5.193 0 0 0-.803-.33l-.25-1.643c-.018-.152-.161-.268-.322-.268H8.673a.326.326 0 0 0-.321.25c-.143.536-.197 1.116-.259 1.661a5.346 5.346 0 0 0-.813.339l-1.232-.955a.393.393 0 0 0-.232-.089c-.304 0-1.509 1.303-1.723 1.598a.343.343 0 0 0-.081.205c0 .081.036.152.09.214.33.402.652.804.955 1.233a4.65 4.65 0 0 0-.348.821l-1.661.25c-.134.027-.241.188-.241.321v1.983c0 .151.107.294.259.321l1.634.241a4.7 4.7 0 0 0 .366.884c-.304.42-.634.813-.937 1.223a.346.346 0 0 0-.009.411c.375.518 1 1.071 1.473 1.5a.32.32 0 0 0 .223.098c.08 0 .161-.027.223-.08l1.259-.956c.259.134.527.242.804.331l.25 1.643c.018.151.16.268.321.268h1.982a.329.329 0 0 0 .322-.25c.143-.536.196-1.117.259-1.661.276-.089.553-.206.812-.339l1.232.964c.072.044.152.08.232.08.304 0 1.509-1.312 1.724-1.598a.3.3 0 0 0 .08-.205.357.357 0 0 0-.089-.224 18.424 18.424 0 0 1-.956-1.232c.143-.259.25-.527.349-.812l1.651-.25c.143-.027.25-.188.25-.322V1.574Z" style="fill-rule:nonzero" transform="translate(-1.664 5.453)" fill="#fff"/></svg>
	p#error(v-if="errored")
		<svg viewBox="0 0 16 16" width="256" height="256" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
			<title>Error:</title>
			<g transform="matrix(1,0,0,1,1.14285,13.7143)">
				<path d="M10.259,-3.696C10.259,-3.545 10.196,-3.393 10.089,-3.286L9.286,-2.482C9.179,-2.375 9.027,-2.313 8.875,-2.313C8.723,-2.313 8.58,-2.375 8.473,-2.482L6.857,-4.098L5.241,-2.482C5.134,-2.375 4.991,-2.313 4.839,-2.313C4.688,-2.313 4.536,-2.375 4.429,-2.482L3.625,-3.286C3.518,-3.393 3.455,-3.545 3.455,-3.696C3.455,-3.848 3.518,-3.991 3.625,-4.098L5.241,-5.714L3.625,-7.33C3.518,-7.438 3.455,-7.58 3.455,-7.732C3.455,-7.884 3.518,-8.036 3.625,-8.143L4.429,-8.946C4.536,-9.054 4.688,-9.116 4.839,-9.116C4.991,-9.116 5.134,-9.054 5.241,-8.946L6.857,-7.33L8.473,-8.946C8.58,-9.054 8.723,-9.116 8.875,-9.116C9.027,-9.116 9.179,-9.054 9.286,-8.946L10.089,-8.143C10.196,-8.036 10.259,-7.884 10.259,-7.732C10.259,-7.58 10.196,-7.438 10.089,-7.33L8.473,-5.714L10.089,-4.098C10.196,-3.991 10.259,-3.848 10.259,-3.696ZM13.714,-5.714C13.714,-9.5 10.643,-12.571 6.857,-12.571C3.071,-12.571 0,-9.5 0,-5.714C0,-1.929 3.071,1.143 6.857,1.143C10.643,1.143 13.714,-1.929 13.714,-5.714Z" style="fill-rule:nonzero;"/>
			</g>
		</svg>
		| {{errorMessage}}
	p#status(vi-if="statusMessage", v-html="statusMessage")
	floating-tooltip
	tooltip-seat
</template>
<script>
import PlanView from 'components/plan/PlanView'
import FloatingTooltip from 'components/FloatingTooltip'
import TooltipSeat from 'components/TooltipSeat'
import store from './store'
import {mapGetters, mapState} from 'vuex'

// TODO: store ggf. deaktivieren und in App auf child-component events umstellen
// Plan braucht da nicht viele: select-seat, hover-seat (für tooltip)
// event und plan könnte man als props übergeben

export default {
	store,
	components: {PlanView, FloatingTooltip, TooltipSeat},
	props: {
		event: String,
		plan: String,
		panWithModifier: {
			type: Boolean,
			required: false,
			default: false,
		},
		scrollZoomWithModifier: {
			type: Boolean,
			required: false,
			default: true,
		},
		styleSrc: {
			type: String,
			required: false,
			default: 'css/pretixSeatingPlan.css',
		}
	},
	data () {
		return {
			loading: true,
			errored: false,
			eventLoadingController: null,
			statusMessage: '',
			errorMessage: '',
		}
	},
	computed: {
		...mapGetters('ui', ['_']),
		// rename due to name-collision with isSeatAvailable from methods (for API)
		...mapGetters('event', {getItem: 'getItem', _isSeatAvailable: 'isSeatAvailable', isSoldOut: 'isSoldOut', validation: 'validation'}),
		...mapGetters('plan', ['getSeatByGuid']),
		...mapState(['selection', 'zoomTransform']),
	},
	watch: {
		event (newValue) {
			console.warn('setting pretix-seating.event through JavaScript is currently not supported')
		},
		plan (newValue) {
			console.warn('setting pretix-seating.plan through JavaScript is currently not supported')
		},
		selection: {
			// immediate: true,
			handler (newValue, oldValue) {
				if (!newValue.length && !oldValue.length) return
				const seats = []
				// TODO: reactive seat object passed to outside - is that a good idea?
				newValue.forEach(s => {
					seats.push({seat: s, item: this.getItem(s.category)})
				})
				this.$nextTick(() => this.$emit('select', seats))
			}
		},
		zoomTransform: {
			// immediate: true,
			handler (newValue, oldValue) {
				this.$emit('transform', {
					k: newValue.k,
					x: newValue.x,
					y: newValue.y
				})
			}
		},
		isSoldOut (newValue, oldValue) {
			this.$emit(newValue ? 'soldout' : 'reopen')
		},
	},
	mounted () {
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', this.onSlotsUpdate)
		} else {
			this.onSlotsUpdate()
		}

		// load event- and plan-data in parallel
		this.eventLoadingController?.abort()
		this.eventLoadingController = new AbortController()
		Promise
			.all([
				fetch(this.event, {signal: this.eventLoadingController.signal}),
				fetch(this.plan)
			])
			.then((responses) => {
				responses.forEach(response => {
					if (!response.ok) throw Error(`${response.status}: ${response.statusText}`)
				})
				const json = responses.map((response) => response.json())
				return Promise.all(json)
			})
			.then(data => {
				// map responses to store-mutations
				['loadEvent', 'loadPlan'].forEach((mutation, index) => {
					this.$store.commit(mutation, {
						data: data[index]
					})
				})
				this.loading = this.errored = false
				this.statusMessage = this.errorMessage = ''
				this.$emit('load')
			})
			.catch(error => {
				// could be network-errors, HTTP-errors or JSON-errors
				this.errored = true
				this.loading = false
				this.statusMessage = error.message
				this.errorMessage = this._('Could not load seating plan/event data')
				this.$emit('error', error)
			})
			.finally(() => {
				this.eventLoadingController = null
			})
	},
	methods: {
		// these methods are exposed as API on the CustomElement, so choose wisely
		refresh () {
			// reload event-data in background, fail silently?
			// abort existing requests loading event-data
			this.eventLoadingController?.abort()
			this.eventLoadingController = new AbortController()
			fetch(this.event, { signal: this.eventLoadingController.signal })
				.then(response => {
					if (!response.ok) throw Error(response.statusText)
					return response.json()
				})
				.then(data => {
					this.eventLoadingController = null
					this.$store.commit('loadEvent', {data})
				})
				.catch(error => {
					console.error(error)
				})
		},
		selectSeat (guid) {
			if (this.loading) {
				return this.$once('load', () => this.selectSeat(guid))
			}
			const seat = this.getSeatByGuid(guid)
			if (seat && this._isSeatAvailable(seat)) this.$store.commit('select', {seats: [seat]})
		},
		unselectSeat (guid) {
			const seat = this.getSeatByGuid(guid)
			if (seat) this.$store.commit('unselect', {seats: [seat]})
		},
		isSeatAvailable (guid) {
			const seat = this.getSeatByGuid(guid)
			return this._isSeatAvailable(seat)
		},
		setTransform (t) {
			this.$store.commit('setZoomTransform', t)
		},
		getSelectedSeats () {
			const seats = []
			this.selection.forEach(s => {
				seats.push({seat: s.seat_guid, item: this.getItem(s.category)?.id || null})
			})
			return seats
		},
		getValidationRules () {
			return this.validation()
		},
		onSlotsUpdate () {
			this.$slots?.default?.forEach(vNode => {
				if (vNode.tag !== 'SCRIPT') {
					return
				}
				try {
					const settings = JSON.parse(vNode.elm.textContent)
					if (settings.translation) {
						this.$store.commit('setTranslation', {translation: settings.translation})
					}

					const thisObj = this
					function setSelectedSeats () {
						const seats = (settings.selected_seats || []).map(s => thisObj.getSeatByGuid(s)).filter(e => e)
						this.$store.commit('select', {seats})
					}
					if (this.loading) {
						this.$once('load', setSelectedSeats)
					} else {
						setSelectedSeats()
					}
				} catch (e) {
					console.error(e)
				}
			})
		},
	}
}
</script>
<style lang="stylus">
	@keyframes spin {
		from {
			transform:rotate(0deg);
		}
		to {
			transform:rotate(360deg);
		}
	}
	:host
		display: block
		position: relative
		--border-radius: 0

	#app
		height: 100%
		position: relative

	#loading, #error
		position: absolute
		top: 0
		left: 0
		margin: 0
		width: 100%
		height: 100%
		display: flex
		align-items: center
		justify-content: center
		color: var(--text-muted, #747474)

	#loading svg
		position: absolute
		top: 50%
		left: 50%
		margin-top: -128px
		margin-left: -128px
		width: 256px
		height: 256px
		-webkit-animation: spin 8s infinite linear
		animation: spin 8s infinite linear
	#loading path
		fill: #eee

	#error
		color: var(--brand-danger, #c30)
		font-weight: bold

	#error svg
		width: 24px
		height: 24px
		fill: var(--brand-danger, #c30)
		margin-right: .25em

	#status
		position: absolute
		left: 0
		bottom: 0
		color: #999
		padding: .5em
		margin: 0

	.text-muted
		color: var(--text-muted, #767676)
</style>
