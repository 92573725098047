<template lang="pug">
</template>
<script>
/*
This decouples Plan from Tooltip, so one can show Custom Tooltips on Seat-hover in Plan
*/
import {mapGetters, mapState} from 'vuex'

export default {
	computed: {
		...mapGetters('ui', ['_']),
		...mapState(['seatInFocus', 'zoomTransform']),
		...mapGetters('event', ['getItem', 'getTooltip', 'isSeatAvailable']),
	},
	watch: {
		seatInFocus: function (newValue, oldValue) {
			if (!newValue) {
				this.$store.commit('clearTooltip')
				return
			}
			if (oldValue && newValue.seat === oldValue.seat) {
				return
			}
			const element = newValue.element
			const seat = newValue.seat
			const item = this.getItem(seat.category)
			const row = seat.row
			const label = (
				// TODO: What if zone has no name or row should be empty labelled (empty string after trim())?
				this._('%(zone)s - %(row)s - %(seat)s')
					.replace('%(zone)s', row.zone.name)
					.replace('%(row)s', (row.row_label || this._('Row %s')).replace('%s', row.row_number))
					.replace('%(seat)s', (row.seat_label || this._('Seat %s')).replace('%s', seat.seat_number))
			)
			let description = this.getTooltip(seat) || ''
			if (description) description += '\n'
			if (item && this.isSeatAvailable(seat)) {
				description += this._('%(item)s\n%(price)s')
					.replace('%(item)s', item.name)
					.replace('%(price)s', this.formatPrice(item))
			} else {
				description += this._('This seat is currently not available.')
			}

			this.$store.commit('tooltip', { label, description, element })
		},
		zoomTransform: function (newValue, oldValue) {
			this.$store.commit('clearTooltip')
		},
	},
	mounted () {
		window.addEventListener('touchstart', (e) => {
			this.$destroy()
		}, {
			once: true,
			capture: true, // capture:true as d3 will stopPropagation
		})
	},
	methods: {
		formatPrice (item) {
			if (!item.variations || !item.variations.length) return item.price
			const prices = item.variations.map(v => v.price)
			return prices.join(' / ')
		}
	}
}
</script>
<style lang="stylus">
</style>
