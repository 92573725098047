<template lang="pug">
li(:class="classObject")
	input(type="checkbox" @change="onChange" :checked="isChecked" :id="inputName")
	label(:for="inputName")
		strong {{item.name}}
		span.item-price.text-muted {{priceLabel}}
	span.cat-dots
		span.cat-dot(v-for="idx in categoryIndexes" :style="categoryDotStyle(idx)")
</template>
<script>
import {mapGetters} from 'vuex'

export default {
	props: {
		item: Object,
	},
	data () {
		return {

		}
	},
	computed: {
		...mapGetters('plan', ['categories']),
		...mapGetters('filter', ['isFiltered']),
		isChecked () {
			return !this.isFiltered(this.item)
		},
		categoryIndexes () {
			const indexes = []
			this.item.categories.forEach(category => {
				const idx = this.categories.findIndex(c => c.name === category)
				if (idx > -1) indexes.push(idx)
			})
			return indexes
		},
		classObject () {
			return {
				'item-filter-item': true,
				// isChecked: this.isChecked,
			}
		},
		inputName () {
			return 'item-filter-' + this.item.id
		},
		priceLabel () {
			if (!this.item.variations || !this.item.variations.length) return this.item.price
			const prices = this.item.variations.map(v => v.price)
			// filter to unique prices in case variations have the same price
			return prices.filter((v, i, a) => a.indexOf(v) === i).join(' / ')
		}
	},
	methods: {
		categoryDotStyle (idx) {
			return `background: var(--category-${idx}-color)`
		},
		onChange (event) {
			this.$store.commit(event.target.checked ? 'filter/remove' : 'filter/add', { item: this.item })
		}
	},
}
</script>
<style lang="stylus">
	.item-filter-item
		display: flex
		align-items: flex-start
		margin: 0
		padding: 0.7em 0 0.4em
		gap: 0.25em

		&+.item-filter-item
			border-top: 1px solid #ccc

		label
			flex: 1
			cursor: pointer
			display: flex
			flex-wrap: wrap
			gap: .5em

			strong
				flex: 1

		.cat-dot
			display: inline-block
			width: 1em
			height: 1em
			border-radius: 50%
			border: 2px solid #fff
			margin-right: 0.4em

		.cat-dot + .cat-dot
			margin-left: -1.1em

</style>
