<template lang="pug">
g.area(:transform="transform")
	circle(v-if="area.shape === 'circle'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" cx="0", cy="0", :r="area.circle.radius", stroke-width="2px")
	ellipse(v-if="area.shape === 'ellipse'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" cx="0", cy="0", :rx="area.ellipse.radius.x", :ry="area.ellipse.radius.y", stroke-width="2px")
	rect(v-if="area.shape === 'rectangle'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" x="0", y="0", :width="area.rectangle.width", :height="area.rectangle.height", stroke-width="2px")
	polygon(v-if="area.shape === 'polygon'", :fill="area.color || '#888888'", :stroke="area.border_color || '#888888'" x="0", y="0", :points="polygonPoints", stroke-width="2px")
	text(v-if="area.text.text", :x="area.text.position.x", :y="area.text.position.y", :font-size="area.text.size ? area.text.size : 16", text-anchor="middle", font-family="sans-serif", dy=".3em", :fill="area.text.color ? area.text.color : '#888888'", ref="text", aria-hidden="true") {{ area.text.text }}
</template>
<script>

export default {
	props: {
		area: Object,
		zone: null,
	},
	computed: {
		transform () {
			if (this.area.rotation) {
				return `translate(${this.area.position.x}, ${this.area.position.y}) rotate(${this.area.rotation})`
			}
			return `translate(${this.area.position.x}, ${this.area.position.y})`
		},
		polygonPoints () {
			return this.area.polygon.points.map(point => `${point.x},${point.y}`).join(' ')
		}
	},
}
</script>
<style lang="stylus">
</style>
