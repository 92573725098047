<template lang="pug">
div(v-if="cursor", :class="cssClasses")
</template>
<script>

export default {
	props: {
		cursor: {
			type: String,
			required: false,
			default: '',
		}
	},
	computed: {
		cssClasses () {
			return 'cursor-addon ' + this.cursor
		},
	},
	mounted () {
		window.addEventListener('mousemove', this.mousemove)
	},
	destroyed () {
		window.removeEventListener('mousemove', this.mousemove)
	},
	methods: {
		mousemove (e) {
			const bbox = this.$el.parentNode.getBoundingClientRect()
			this.$el.style.left = (event.clientX - bbox.x) + 'px'
			this.$el.style.top = (event.clientY - bbox.y) + 'px'
		},
	}
}
</script>
<style lang="stylus">
	.cursor-addon
		margin-top: -.6em
		margin-left: -.8em
		color: black
		position: absolute
		top: 0
		left: 0
		font-weight: bold

	.cursor-addon.add
		display: block
		&:before {
			content: "+"
		}
	.cursor-addon.remove
		display: block
		&:before {
			font-weight: bold
			content: "–"
		}
</style>
