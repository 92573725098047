<template lang="pug">
.c-plan
	svg(v-if="plan.size", :viewport="viewport", preserveAspectRatio="none", ref="svg", id="plan-svg", @mousedown="mousedown", @focusin="onFocusin", @dblclick="onDblclick", role="group")
		g(
			:transform="$store.state.zoomTransform.toString()"
			:class="mainclasses"
		)
			g(
				ref="toggle"
				role="button"
				tabindex="0"
				v-bind:aria-label="_('Select a zone')"
				aria-controls="plan-view"
				v-bind:aria-expanded="plan.active ? 'true' : 'false'"
				@click="toggleActive"
				@keydown.space="toggleActive"
				@keydown.enter="toggleActive"
				@focus.once="setToggleSize"
			)
				rect(ref="outline" vector-effect="non-scaling-stroke")
				//- <text> is needed for VoiceOver on iOS Safari to work at all
				text {{ _('Select a zone') }}
			g(
				v-bind:aria-hidden="plan.active ? 'false' : 'true'"
				id="plan-view"
				ref="container"
			)
				plan-zone(v-for="zone in plan.zones", :zone="zone", :key="zone.index", :plan="plan", ref="zones")
			selection-area(v-if="selectFrom", :offsetParent="$refs.svg", :from="selectFrom", :transform="$store.state.zoomTransform", @select="onAreaSelect")
	cursor-addon(v-if="cursorAddon", :cursor="cursorAddon")
	.button-group
		button(@click="zoomIn" class="btn-zoom btn-zoom-in") {{ _('Zoom in') }}
		button(@click="zoomOut" class="btn-zoom btn-zoom-out") {{ _('Zoom out') }}
	items-filter
	instructions-overlay
</template>
<script>
import {lighten} from '../../lib/colors'
import {mapGetters, mapState} from 'vuex'
import ItemsFilter from './ItemsFilter'
import CursorAddon from './CursorAddon'
import InstructionsOverlay from './InstructionsOverlay'
import PlanZone from './PlanZone'
import SelectionArea from './SelectionArea'
import * as d3 from 'd3'

export default {
	components: { ItemsFilter, CursorAddon, InstructionsOverlay, PlanZone, SelectionArea },
	props: {
		scrollZoomWithModifier: Boolean,
		panWithModifier: Boolean,
		translation: Object,
	},
	data () {
		return {
			zoom: null,
			viewportWidth: 0,
			viewportHeight: 0,
			fullScreen: true,
			lastMouseUp: 0,

			selectFrom: null,
			cursorAddon: null,

			unwatchDragSelectHint: null,

			// debounce resize (this.createZoom) as it is expensive
			// when scrolling on iOS the browser-chrome shrinks and triggers a resize-event
			// check on resize if a scroll-event happened in the last 500ms
			resizeTimeout: null,
			recentlyScrolled: false,
			recentlyScrolledTimeout: null,
			instructionsTimeout: null,

			minHitArea: 16,
		}
	},
	computed: {
		...mapGetters('plan', ['categories', 'plan']),
		...mapGetters('event', ['isSeatAvailable', 'getItem', 'getItemById']),
		...mapGetters('filter', {filteredItems: 'items', isItemFiltered: 'isFiltered'}),
		...mapGetters('ui', ['_']),
		...mapState(['zoomTransform']),
		isMac () {
			return window.navigator.platform.toLowerCase().startsWith('mac')
		},
		mainclasses () {
			return {
				'zoom-transform': true
			}
		},
		viewport () {
			const w = this.plan.size.width
			const h = this.plan.size.height
			return `0 0 ${w} ${h}`
		},
	},
	watch: {
		categories: {
			immediate: true,
			handler (newValue, oldValue) {
				// provide category-colors as CSS Custom properties for e.g. seats
				const setProperties = () => {
					newValue.forEach((category, index) => {
						this.$el.style.setProperty(`--category-${index}-color`, category.color)
					})
				}
				if (!this.$el) {
					this.$once('hook:mounted', setProperties)
				} else {
					setProperties()
				}
			},
		},
		'plan.active' (newValue) {
			// do not focus first available zone if a seat has focus already
			if (!newValue || this.seatInFocus) return
			this.$refs.zones[0]?.focus()
		},
		filteredItems (newValue, oldValue) {
			oldValue.filter(id => !newValue.includes(id)).forEach(id => {
				const item = this.getItemById(id)
				this.categories.forEach((category, index) => {
					if (item.categories.includes(category.name)) {
						this.$refs.svg.style.removeProperty(`--category-${index}-color`)
					}
				})
			})
			newValue.filter(id => !oldValue.includes(id)).forEach(id => {
				const item = this.getItemById(id)
				this.categories.forEach((category, index) => {
					if (item.categories.includes(category.name)) {
						this.$refs.svg.style.setProperty(`--category-${index}-color`, lighten(category.color, 0.8))
					}
				})
			})
		},
	},
	mounted () {
		this.$nextTick(() => {
			this.createZoom()
		})
		window.addEventListener('scroll', () => {
			if (!this.recentlyScrolled) {
				this.onScroll()
			}
		})

		// wait for user to use the mouse to enable multi-selection hint
		const multiSelectInstructionsListener = () => {
			this.unwatchDragSelectHint = this.$store.watch(
				(state, getters) => getters.selection,
				(newValue, oldValue) => {
					if (newValue.length < 8) {
						return
					}
					const msg = this.panWithModifier
						? this._('You can click and drag to select multiple seats.')
						: this._('When you press <kbd>' + (this.isMac ? 'CMD' : 'CTRL') + '</kbd>, you can click and drag to select multiple seats.')
					this.$store.commit('instructions', { msg })
					window.clearTimeout(this.instructionsTimeout)
					// wait a bit before enabling removing instruction on mousedown
					window.setTimeout(() => {
						window.addEventListener('mousedown', () => {
							this.$store.commit('clearInstructions')
						}, { once: true })
					}, 1000)

					// only show hint once
					this.unwatchDragSelectHint()
				}
			)
		}
		window.addEventListener('mousemove', multiSelectInstructionsListener, { once: true })

		window.addEventListener('touchstart', () => {
			this.unwatchDragSelectHint?.()
			window.removeEventListener('mousemove', multiSelectInstructionsListener)
		}, true) // capture:true as d3 will stopPropagation
	},
	created () {
		window.addEventListener('touchstart', event => {
			// increase minHitArea for touch-devices - Apple’s recommendation is at least 44px
			this.minHitArea = 44
		}, { once: true })

		window.addEventListener('resize', this.onResize)
		this.unwatch = this.$store.watch(
			(state, getters) => getters['plan/planSize'],
			(newValue, oldValue) => {
				if (!oldValue || newValue.width !== oldValue.width || newValue.height !== oldValue.height) {
					this.$nextTick(() => {
						this.createZoom()
					})
				}
			}
		)
	},
	destroyed () {
		window.removeEventListener('resize', this.onResize)
		this.unwatch()
	},
	methods: {
		toggleActive (event) {
			this.$store.commit('toggleActive', this.plan)
		},
		setToggleSize () {
			const bbox = this.$refs.container.getBBox()
			const outline = this.$refs.outline
			this.$refs.toggle.setAttribute('transform', `translate(${bbox.x},${bbox.y})`)
			outline.setAttribute('width', bbox.width)
			outline.setAttribute('height', bbox.height)
		},
		// see doc regarding resizeTimeout, recentlyScrolled and recentlyScrolledTimeout
		onScroll () {
			if (this.recentlyScrolledTimeout) {
				clearTimeout(this.recentlyScrolledTimeout)
			}
			this.recentlyScrolled = true
			this.recentlyScrolledTimeout = window.setTimeout(() => {
				this.recentlyScrolled = false
			}, 1000)
		},
		// see doc regarding resizeTimeout, recentlyScrolled and recentlyScrolledTimeout
		onResize () {
			if (this.resizeTimeout) {
				clearTimeout(this.resizeTimeout)
			}
			// only reposition on larger resizes, this e.g. also helps fix scroll-triggered
			// resize events due to browser-chrome being removed on mobile browsers onscroll
			// alternatively setting this.resizeTimeout could be moved inside an
			// if (!this.recentlyScrolled) to mitigate scroll-induced resize events
			this.resizeTimeout = window.setTimeout(() => {
				const dw = Math.abs(this.viewportWidth - this.$refs.svg.clientWidth)
				const dh = Math.abs(this.viewportHeight - this.$refs.svg.clientHeight)
				this.viewportWidth = this.$refs.svg.clientWidth
				this.viewportHeight = this.$refs.svg.clientHeight

				const defaultScale = this.plan.size.height ? Math.min(this.viewportWidth / this.plan.size.width, this.viewportHeight / this.plan.size.height) : 1
				this.zoom.scaleExtent([defaultScale * 0.9, 2.5])
				if (dw / this.viewportWidth > 0.25 || dh / this.viewportHeight > 0.25) {
					// if either dimension changed more than 25%
					const initTransform = d3.zoomIdentity
						.scale(defaultScale)
						.translate(
							(this.viewportWidth / defaultScale - this.plan.size.width) / 2,
							(this.viewportHeight / defaultScale - this.plan.size.height) / 2
						)
					this.$store.commit('setZoomTransform', initTransform)
					d3.select(this.$refs.svg).call(this.zoom.transform, initTransform)
				}
			}, 500)
		},
		onFocusin (event) {
			const element = event.target
			if (element.classList.contains('seat')) {
				// a seat has received focus, check whether we need to zoom in and/or pan
				const elementBBox = element.getBBox()
				const centerPoint = element.viewportElement.createSVGPoint()
				centerPoint.x = elementBBox.x + (elementBBox.width / 2)
				centerPoint.y = elementBBox.y + (elementBBox.height / 2)

				const centerPointRelToViewport = centerPoint.matrixTransform(element.viewportElement.getScreenCTM().inverse().multiply(element.getScreenCTM()))
				// check if hitArea is big enough, if not => zoom
				// TODO: elementBBox does not take transforms into account. As we currently only scale with d3 we can safely use that instead of a matrix-transform
				const currentScale = d3.zoomTransform(this.$refs.svg).k
				const hitArea = Math.min(elementBBox.width, elementBBox.height) * currentScale
				// make padding so that 2/3 of circle are visible (center = 0.5 + 0.16 = 0.66)
				const padding = 0.16 * hitArea
				const offsetBbox = element.viewportElement.getBoundingClientRect()
				const xMax = offsetBbox.width - padding
				const yMax = offsetBbox.height - padding

				if (hitArea < this.minHitArea) {
					// if we need to zoom, zoom at least double increment
					const targetScale = Math.max(currentScale * this.minHitArea / hitArea, currentScale * 1.2 * 1.2)
					d3.select(this.$refs.svg)
						.transition()
						.call(this.zoom.scaleTo, targetScale, [centerPointRelToViewport.x, centerPointRelToViewport.y])
				}

				if (centerPointRelToViewport.x < padding || centerPointRelToViewport.x > xMax ||
					centerPointRelToViewport.y < padding || centerPointRelToViewport.y > yMax) {
					// less than 2/3 (0.50 (center) + 0.16) of seat are visible
					const centerPointRelToBaseCoord = centerPoint.matrixTransform(this.$refs.svg.firstChild.getScreenCTM().inverse().multiply(element.getScreenCTM()))
					d3.select(this.$refs.svg)
						.transition()
						.call(this.zoom.translateTo, centerPointRelToBaseCoord.x, centerPointRelToBaseCoord.y)
				}
			}
		},
		onDblclick (event) {
			// zoom on dblclick, zoom twice as fast
			const offsetBbox = this.$refs.svg.getBoundingClientRect()
			const cx = event.clientX - offsetBbox.x
			const cy = event.clientY - offsetBbox.y
			d3.select(this.$refs.svg)
				.transition()
				.call(this.zoom.scaleBy, event.altKey ? 0.8 * 0.8 : 1.25 * 1.25, [cx, cy])
		},
		zoomIn (e) {
			d3.select(this.$refs.svg)
				.transition().call(this.zoom.scaleBy, 1.25 * 1.25)
		},
		zoomOut (e) {
			d3.select(this.$refs.svg)
				.transition().call(this.zoom.scaleBy, 0.8 * 0.8)
		},
		setZoomTransform (t) {
			this.$store.commit('setZoomTransform', t)
		},
		mousedown (event) {
			let isPanning = event.ctrlKey || event.metaKey
			if (!this.panWithModifier) {
				isPanning = !isPanning
			}
			if (!isPanning) {
				this.$store.commit('startAreaSelect')
				this.selectFrom = {x: event.clientX, y: event.clientY}
				this.cursorAddon = event.altKey ? 'remove' : 'add'

				const controller = new AbortController()
				window.addEventListener('keydown', this.setCursorAddon, {signal: controller.signal})
				window.addEventListener('keyup', this.setCursorAddon, {signal: controller.signal})
				window.addEventListener('keydown', (event) => {
					if (event.keyCode === 27) {
						// ESC-key => abort drag-selection
						this.selectFrom = false
						this.cursorAddon = null
						// imediately endAreaSelect
						this.$store.commit('endAreaSelect')
						controller.abort()
					}
				}, {signal: controller.signal})
				window.addEventListener('mouseup', (event) => controller.abort(), { once: true })
			}
		},
		setCursorAddon (event) {
			this.cursorAddon = event.altKey ? 'remove' : 'add'
		},
		getSeatsInArea (from, to) {
			const seats = []
			const xmin = Math.min(from.x, to.x)
			const ymin = Math.min(from.y, to.y)
			const xmax = Math.max(from.x, to.x)
			const ymax = Math.max(from.y, to.y)
			for (const z of this.plan.zones) {
				// TODO: improve performane by caching a containing rect for seats in a zone?
				for (const r of z.rows) {
					// TODO: improve performance by caching a containing rect for seats in a row?
					for (const s of r.seats) {
						const item = this.getItem(s.category)
						if (
							(z.position.x + r.position.x + s.position.x + (s.radius || 10)) >= xmin &&
							(z.position.x + r.position.x + s.position.x - (s.radius || 10)) <= xmax &&
							(z.position.y + r.position.y + s.position.y + (s.radius || 10)) >= ymin &&
							(z.position.y + r.position.y + s.position.y - (s.radius || 10)) <= ymax &&
							this.isSeatAvailable(s) && item && !this.isItemFiltered(item)
						) {
							seats.push(s)
						}
					}
				}
			}
			return seats
		},
		onAreaSelect (e) {
			this.selectFrom = false
			this.cursorAddon = null
			this.unwatchDragSelectHint?.()

			if (Math.abs(e.from.x - e.to.x) > 2 || Math.abs(e.from.y - e.to.y) > 2) {
				const seats = this.getSeatsInArea(e.from, e.to)
				this.$store.commit(e.altKey ? 'unselect' : 'select', {seats})

				// endAreaSelect after click-event is fired on seat, so seat.selected is not toggled twice => setTimeout
				window.setTimeout(() => this.$store.commit('endAreaSelect'), 50)
			} else {
				// just a click, no movement => imediately endAreaSelect so click event on seat selects seat
				this.$store.commit('endAreaSelect')
			}
		},
		createZoom () {
			if (!this.$refs.svg) return

			let prevZoomTransform = null
			const padding = 40
			this.viewportWidth = this.$refs.svg.clientWidth
			this.viewportHeight = this.$refs.svg.clientHeight
			const defaultScale = this.plan.size.height ? Math.min(this.viewportWidth / this.plan.size.width, this.viewportHeight / this.plan.size.height) : 1

			this.zoom = d3.zoom()
				// let users zoom a little less than default scale so when using scroll to zoom they know it works even if they scroll the wrong direction
				.scaleExtent([defaultScale * 0.9, 2.5])
				// translateExtent is calculated dynamically on.zoom
				// .translateExtent([[0, 0], [this.plan.size.width, this.plan.size.height]])
				.filter(event => {
					let modifierFlag = !event.metaKey && !event.ctrlKey
					const wheeled = event.type === 'wheel'
					const mouseDrag =
						event.type === 'mousedown' ||
						event.type === 'mouseup' ||
						event.type === 'mousemove'
					const touch =
						event.type === 'touchstart' ||
						event.type === 'touchmove' ||
						event.type === 'touchstop'

					// TODO: due to scroll inertia in e.g. Mac we need to debounce this
					// otherwise when zooming and letting CMD-key go to early we immediately get this message
					if (wheeled && this.scrollZoomWithModifier && !(event.metaKey || event.ctrlKey)) {
						if (!this.recentlyScrolled) {
							this.onScroll()
							this.$store.commit('instructions', {
								msg: this.isMac
									? this._('Hold <kbd>CMD</kbd> and scroll to zoom.')
									: this._('Hold <kbd>CTRL</kbd> and scroll to zoom.')
							})
						}
						window.clearTimeout(this.instructionsTimeout)
						this.instructionsTimeout = window.setTimeout(() => this.$store.commit('clearInstructions'), 2500)
						return false
					} else {
						// TODO: this should only be cleared if instructions are the scroll-zoom instructions
						window.clearTimeout(this.instructionsTimeout)
						this.$store.commit('clearInstructions')
					}
					// due to scroll inertia on Mac touchpads, block instructions from being set again
					if (wheeled) {
						this.onScroll()
					}
					if ((wheeled && this.scrollZoomWithModifier) || (mouseDrag && this.panWithModifier)) {
						modifierFlag = !modifierFlag
					}
					return (wheeled || mouseDrag || touch) && modifierFlag
				})
				.on('start.drag', (event) => {
					this.$store.commit('startDrag')
					prevZoomTransform = event.transform
				})
				.on('end.drag', (event) => {
					this.$store.commit('endDrag')
					// on sloppy mouse clicks an unintended pan (< 3px) event can happen, which blocks
					// click-events and by that blocks seat selection
					// check if it is a short pan and try to select seat, if any is in sourceEvent
					const e = event.sourceEvent
					const dx = Math.abs(event.transform.x - prevZoomTransform.x)
					const dy = Math.abs(event.transform.y - prevZoomTransform.y)
					// we need at least 1px movement on one axis, otherwise the normal click event on the seat will be fired as well
					const tinypan = (dx > 0 || dy > 0) && (dx < 5 && dy < 5)
					if (e && tinypan) {
						// Firefox offers originalTarget, Chrome offers path
						const seatNode = e.originalTarget?.closest('.seat') || e.path?.find(node => node.classList && node.classList.contains('seat'))
						// SVG-elements do not support .click()-method, so dispatchEvent
						seatNode?.dispatchEvent(new Event('click'))
					}
				})
				.on('zoom', (event) => {
					const scale = event.transform.k
					this.zoom.translateExtent([
						[(-this.viewportWidth + padding) / scale, (-this.viewportHeight + padding) / scale],
						[(this.viewportWidth - padding) / scale + this.plan.size.width, (this.viewportHeight - padding) / scale + this.plan.size.height]
					])
					this.$store.commit('setZoomTransform', event.transform)
				})
			const initTransform = d3.zoomIdentity
				.scale(defaultScale)
				.translate(
					(this.viewportWidth / defaultScale - this.plan.size.width) / 2,
					(this.viewportHeight / defaultScale - this.plan.size.height) / 2
				)
			this.$store.commit('setZoomTransform', initTransform)

			// This sets correct d3 internal state for the initial centering
			const zoomElement = d3.select(this.$refs.svg)
				.call(this.zoom.transform, initTransform)
				.call(this.zoom)
				.on('wheel', function (event) {
					if (event.metaKey || event.ctrlKey) {
						// Prevent native-scroll-zooming when the min/max of the zoom extent is reached
						event.preventDefault()
					}
				})
				.on('click', event => {
					// If seats are too small to see, zoom and move upon click for better usability
					if (event.defaultPrevented || event.originalTarget?.closest('.seat') || event.path?.find(node => node.classList && node.classList.contains('seat'))) {
						// if a seat has focus, zoom will happen through onFocusIn
						return
					}
					const currentScale = d3.zoomTransform(this.$refs.svg).k
					// get hitArea of first available seat (could have empty zones and/or rows)
					const hitArea = (function (zones) {
						for (const zone of zones) {
							for (const row of (zone.rows || [])) {
								for (const seat of (row.seats || [])) {
									if (seat.radius) {
										return seat.radius * 2
									}
								}
							}
						}
						return 25
					})(this.plan.zones) * currentScale

					if (hitArea < this.minHitArea) {
						// if we need to zoom, zoom at least double increment
						const viewportElement = this.$refs.svg
						const targetScale = Math.max(currentScale * this.minHitArea / hitArea, currentScale * 1.2 * 1.2)

						const centerPoint = viewportElement.createSVGPoint()
						centerPoint.x = event.clientX
						centerPoint.y = event.clientY
						const centerPointRelToViewport = centerPoint.matrixTransform(viewportElement.getScreenCTM().inverse())
						zoomElement.transition()
							.call(this.zoom.scaleTo, targetScale, [centerPointRelToViewport.x, centerPointRelToViewport.y])
					}
				}, { once: true })

			// to disable pan with one finger on touch-devices, we need to
			// save the original touchmove.zoom-handler and overwrite it
			// with our custom-handler that calls the original handler when
			// more than one touches are registered
			if (this.panWithModifier) {
				const onTouchMoveZoom = zoomElement.on('touchmove.zoom')
				if (onTouchMoveZoom) {
					let recentlyZoomed = false
					let recentlyZoomedTimeout
					zoomElement.on('touchmove.zoom', (event) => {
						if (event.touches && event.touches.length > 1) {
							onTouchMoveZoom.bind(this.$refs.svg)(event)
							recentlyZoomed = true
							if (recentlyZoomedTimeout) window.clearTimeout(recentlyZoomedTimeout)
							recentlyZoomedTimeout = window.setTimeout((event) => recentlyZoomed = false, 500)
						} else if (!recentlyZoomed) {
							// TODO: this happens on a „sloppy“ touch/click. Wait for a certain scroll-distance to show hint?
							// Alternative: only show instruction once per session?
							this.$store.commit('instructions', {msg: this._('Use two fingers to zoom or move around.')})
							window.clearTimeout(this.instructionsTimeout)
							this.instructionsTimeout = window.setTimeout(() => this.$store.commit('clearInstructions'), 2500)
						}
					})
				}
			}
		},
	}
}
</script>
<style lang="stylus">
	.c-plan
		position: relative
		height: 100%

		g:not(.seat):focus
			outline: none

		g[role=button] text
			visibility: hidden
		[aria-hidden=true] g[role=button] text
			display: none

		g[role=button] rect
			fill: none
			stroke: var(--brand-primary, #7f5a91)
			stroke-width: 2
			display: none
		g[role=button]:focus rect
			display: block

		.button-group
			position: absolute
			bottom: 1.5em
			right: 1.5em
			display: flex
			flex-direction: column
			border: 1px solid rgba(0,0,0,.25)
			border-radius: var(--border-radius, 0)

			.btn-zoom
				width: 32px
				height: 32px
				text-indent: 100%
				overflow: hidden
				white-space: nowrap
				padding: 0
				margin: 0
				cursor: pointer
				background-color: rgba(236,236,236,.8)
				background-repeat: no-repeat
				background-size: 16px 16px
				background-position: center
				border: none
			.btn-zoom:hover
				background-color: #f9f9f9
			.btn-zoom + .btn-zoom
				border-top: 1px solid #ddd
			.btn-zoom-in
				border-top-left-radius: var(--border-radius, 0)
				border-top-right-radius: var(--border-radius, 0)
				background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23555' stroke-width='2' d='M0 8h16M8 0v16'/%3E%3C/svg%3E");
			.btn-zoom-out
				border-bottom-left-radius: var(--border-radius, 0)
				border-bottom-right-radius: var(--border-radius, 0)
				background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23555'  stroke-width='2' d='M0 8h16'/%3E%3C/svg%3E");

		#plan-svg
			width: 100%
			height: 100%
			max-height: 100%
			display: block

			&:focus
				border: none
				outline: none

			&:focus-visible
				outline: 2px solid var(--brand-primary, #7f5a91)

			*
				user-select: none

			.selection-area
				vector-effect: non-scaling-stroke
				stroke-width: 1px
				fill: rgba(0, 0, 204, 0.15)
				stroke: rgba(0, 0, 204, 0.4)
				stroke-style: solid
</style>
