<template lang="pug">
.seating-checkout
	p.alert.alert-warning(v-if="errorMessage", aria-live="polite") {{errorMessage}}
	p
		button.btn.btn-primary.btn-block.btn-lg(v-if="isValid", type="submit")
			i.fa.fa-cart-plus(aria-hidden="true")
			|
			| {{checkoutLabel}}
		button.btn.btn-info.btn-block(v-if="isFixable", type="button", @click="onOptimize")
			i.fa.fa-wrench(aria-hidden="true")
			|
			| {{gettext("Propose similar seats")}}
</template>
<script>
import {
	hasSingles, optimizeSelection, selectionCanBeOptimized
} from 'lib/validation'

export default {
	props: {
		seatingPlan: {
			// CSS-selector for plan, usually #<id>
			type: String,
			required: false,
			default: 'pretix-seating-plan'
		},
		translation: {
			type: Object,
			required: false,
			default (rawProps) {
				return {}
			}
		},
	},
	data () {
		return {
			// Array of seat-Objects
			selection: [],
			// CustomElement/DOM-Node of pretix-seatingplan
			planElement: null,
			// currently methods are not exposed, so we need vueComponent of plan-DOMNode
			planComponent: null,
			errorMessage: '',
			isValid: false,
			isFixable: false,
			min: 1,
			max: null,
			optimize: false,
			allowSingles: true,
		}
	},
	computed: {
		checkoutLabel () {
			if (this.selection.length === 1) return this.gettext('Add one ticket to cart')
			return this.gettext('Add %d tickets to cart').replace('%d', this.selection.length)
		}
	},
	watch: {
		seatingPlan: {
			immediate: true,
			handler (newValue, oldValue) {
				window.customElements.whenDefined('pretix-seating-plan').then(() => {
					if (document.readyState === 'loading') {
						document.addEventListener('DOMContentLoaded', this.setPlan)
					} else {
						this.setPlan()
					}
				})
			}
		},
		selection (newValue) {
			this.validate()
			this.$root.$emit('validate', {valid: this.isValid, fixable: this.isFixable})
		},
	},
	methods: {
		gettext (s) {
			return this.translation[s] || s
		},
		validate () {
			this.isFixable = false
			this.isValid = false
			const nrOfSeats = this.selection.length
			if (!nrOfSeats) {
				// this.errorMessage = this.gettext('No seats selected.')
				this.errorMessage = ''
				return false
			}
			if (this.min && nrOfSeats < this.min) {
				this.errorMessage = this.gettext('You need to select at least %(min)s seats.').replace('%(min)s', this.min)
				return false
			}
			if (this.max && nrOfSeats > this.max) {
				if (this.max === 1) {
					this.errorMessage = this.gettext('You can only choose one seat.')
					return false
				}
				const delta = nrOfSeats - this.max
				const msg = delta === 1
					? this.gettext('You can choose no more than %(max)s seats. Please remove one seat from your selection to proceed.')
					: this.gettext('You can choose no more than %(max)s seats. Please remove %(count)s seats from your selection to proceed.')
				this.errorMessage = msg.replace('%(max)s', this.max).replace('%(count)s', delta)
				return false
			}
			// try to optimize before checking allowSingles
			const selectedSeats = this.selection.map(s => s.seat)
			if (this.optimize && selectionCanBeOptimized(selectedSeats, this.isSeatAvailable)) {
				this.isFixable = true
				this.errorMessage = this.gettext('We need you to select different seats to allow us to fill as many seats as possible.')
				return false
			}
			if (this.allowSingles === false && hasSingles(selectedSeats, this.isSeatAvailable)) {
				this.errorMessage = this.gettext('Please do not leave single seats empty.')
				return false
			}
			this.isValid = true
			this.errorMessage = ''
			return true
		},
		isSeatAvailable (seat) {
			return this.planComponent?.isSeatAvailable(seat.seat_guid)
		},
		setPlan () {
			this.planElement = document.querySelector(this.seatingPlan)
			if (!this.planElement) return
			this.planComponent = this.planElement.vueComponent

			this.planElement.addEventListener('load', (e) => {
				this.planComponent = this.planElement.vueComponent
				const validation = this.planComponent.getValidationRules()
				this.max = validation.maximumNumber
				this.optimize = validation.optimizedOrder
				this.allowSingles = validation.allowSingles
			})
			this.planElement.addEventListener('error', (e) => {
				console.error('plan.error-event', e)
			})
			this.planElement.addEventListener('select', (e) => {
				// currently only complete selection is given every time an update happens
				this.selection = e.detail[0]
			})
		},
		onOptimize (e) {
			e.preventDefault()
			const currentSelection = this.selection.map(s => s.seat)
			const newSelection = optimizeSelection(currentSelection, this.isSeatAvailable)
			const droppedSeats = currentSelection.filter(seat => !newSelection.includes(seat))
			droppedSeats/* .filter(seat => seat.variation) */.forEach(seat => {
				this.planComponent.unselectSeat(seat.seat_guid)
				// TODO: fix variation? This somehow magically works – as the same number of seats is selected?
				// currently the selected variation is only saved in pretix-seating-cart, maybe save it in pretix-seating as well?
				/*
				if (!seat.variation) return
				const v = seat.variation
				const c = seat.category
				if (droppedVariations[c]) {
					droppedVariations[c].push(v)
				} else {
					droppedVariations[c] = [v]
				}
				*/
			})

			const addSeats = newSelection.filter(seat => !currentSelection.includes(seat))
			addSeats.forEach(seat => {
				/*
				const c = seat.category
				const v = droppedVariations[c] ? droppedVariations[c].shift() : null
				if (v) {
					console.warn("TODO: select variation on local seat in checkout")
					//this.$store.commit('selectVariation', {seat, variation: v})
				}
				*/
				this.planComponent.selectSeat(seat.seat_guid)
			})
		},
	}
}
</script>
<style lang="stylus">
</style>
